<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
    <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên đề tài <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên đề tài"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="dataForm.name"
                  name="name"
                  placeholder="Nhập tên đề tài"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="3"
            lg="3"
            xl="3"
          >
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã đề tài <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã đề tài"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="dataForm.code"
                  name="code"
                  placeholder="Nhập mã đề tài"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="3"
            lg="3"
            xl="3"
          >
            <b-form-group
              label-for="yearId"
              label="Năm"
            >
              <v-select
                v-model="dataForm.yearId"
                placeholder="Chọn năm"
                :options="years"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="categoryId"
              label="Danh mục"
            >
              <v-select
                v-model="dataForm.categoryId"
                label="name"
                placeholder="Chọn danh mục"
                :options="categories"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="levelId"
              label="Lĩnh vực"
            >
              <v-select
                v-model="dataForm.fieldId"
                label="name"
                placeholder="Chọn lĩnh vực"
                :options="fields"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="levelId"
              label="Cấp độ"
            >
              <v-select
                v-model="dataForm.levelId"
                label="name"
                placeholder="Chọn cấp độ"
                :options="levels"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Tổ chức chủ trì"
              label-for="hostOrganization"
            >
              <b-form-input
                id="hostOrganization"
                v-model="dataForm.hostOrganization"
                name="hostOrganization"
                placeholder="Nhập tổ chức chủ trì"
              />
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Chi phí dự kiến"
              label-for="cost"
            >
              <cleave
                id="cost"
                v-model="dataForm.cost"
                class="form-control"
                :options="numberOption"
                placeholder="Nhập kinh phí thực hiện"
              />
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Ngày bắt đầu"
              label-for="startDate"
            >
              <b-form-datepicker
                id="startDate"
                v-model="dataForm.startDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="vi"
                format="dd/mm/yyyy"
              />
            </b-form-group>
          </b-col>
          <!-- Ngày kết thúc -->
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Ngày kết thúc"
              label-for="endDate"
            >
              <b-form-datepicker
                id="endDate"
                v-model="dataForm.endDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="vi"
                format="dd/mm/yyyy"
              />
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="evaluation"
              label="Kết quả"
            >
              <v-select
                v-model="dataForm.resultEvaluation"
                placeholder="Chọn kết quả"
                :options="researchEvaluations"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="dataForm.status"
                  label="text"
                  :options="researchStatuses"
                  :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Tóm tắt"
              label-for="summary"
            >
              <ckeditor
                ref="ckRef"
                v-model="dataForm.product"
                :config="editorConfig"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <div class="float-left">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="invalid"
                @click.stop.prevent="handleSaveData"
              >
                Lưu
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BForm,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BOverlay,
  BFormDatepicker,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { RESEARCH_EVALUATIONS, RESEARCH_STATUSES } from '@/const/status'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Form',
  components: {
    ValidationObserver,
    ValidationProvider,
    Cleave,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    vSelect,
    BFormDatepicker,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({
        id: null,
        code: '',
        name: '',
        organizationId: null,
        categoryId: null,
        fieldId: null,
        levelId: null,
        hostOrganization: '',
        cost: null,
        startDate: null,
        endDate: null,
        resultEvaluation: null,
        product: '',
        status: 0,
      }),
    },
    modalShow: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      isLoading: false,
      cUser: getUser(),
      numberOption: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      editorConfig: {
        versionCheck: false,
      },
      required,
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      documents: 'documents/documents',
      categories: 'factScientificTopics/dataArr',
      fields: 'factResearchFields/dataArr',
      levels: 'factTopicLevels/dataArr',
      years: 'dropdown/schoolYears'
    }),
    researchEvaluations() {
      return RESEARCH_EVALUATIONS
    },
    researchStatuses() {
      return RESEARCH_STATUSES
    },
  },
  async created() {
    await this.getYears({})
  },
  methods: {
    ...mapActions({
      saveData: 'research/saveData',
      getYears: 'dropdown/getSchoolYears'
    }),
    // handle even
    async handleSaveData() {
      this.isLoading = true
      try {
        this.dataForm.organizationId = this.cUser.orgId
        const res = await this.saveData(this.dataForm)
        if (res.isSuccessful) {
          this.showToast(res.message, 'CheckIcon', 'success')
          this.$bvModal.hide('form')
          this.$emit('saveDataSuccess', true)
        } else {
          this.showToast('Có lỗi xảy ra', 'XCircleIcon', 'danger')
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>
