<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="12">
            <div class="text-right">
              <b-button
                v-if="creatable"
                variant="success"
                @click="showModal"
              >
                <span class="text-nowrap">
                  <feather-icon icon="PlusIcon" /> Thêm mới
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row :class="{'mt-1': creatable}">
          <b-col cols="12">
            <vue-good-table
              mode="remote"
              :columns="table.fields"
              :rows="table.rows"
              :pagination-options="{enabled: true}"
              :total-rows="total"
              :line-numbers="true"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <!-- pagination -->
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Action -->
                <span v-if="props.column.field === 'action'">
                  <b-button
                    v-if="deletable"
                    v-b-tooltip.hover
                    title="Xóa"
                    placement="bottom"
                    variant="danger"
                    class="btn-icon"
                    style="margin-left: 6px"
                    size="sm"
                    @click="deleteItem(props.row)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </span>
                <span v-else-if="props.column.field === 'status'">
                  {{ getStatusName(props.column.status) }}
                </span>
                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
              </template>
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị 1 đến </span>
                    <b-form-select
                      v-model="filter.perPage"
                      :options="['20', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">của {{ total }} bản ghi</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="filter.perPage"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    />
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="formAddEmployee"
      ref="formAddEmployee"
      ok-only
      ok-title="Chấp nhận"
      centered
      size="lg"
      title="Thêm thành viên"
      :hide-footer="true"
      no-close-on-backdrop
    >
      <FormAddEmployee
        :data-form="currentResearch"
        @successFormAdd="successFormAdd"
      />
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BButton, BCard, BCol, BFormSelect, BOverlay, BPagination, BRow, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import '@core/scss/vue/libs/vue-good-table.scss'
import FormAddEmployee from '@/views/research/formAddEmployee.vue'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'FormEmployeeResearch',
  components: {
    VueGoodTable,
    BButton,
    BCard,
    BCol,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    FormAddEmployee,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({
        id: null,
        rowNum: null,
        name: '',
        status: 0,
        orderNo: 1,
        code: '',
        orgId: null,
        fieldId: null,
        categoryId: null,
        levelId: null,
        cost: null,
        startDate: null,
        endDate: null,
      }),
    },
  },
  data() {
    return {
      isLoading: false,
      table: {
        fields: [
          {
            label: 'Tên ',
            field: 'employeeName',
            thClass: 'text-center',
          },
          {
            label: 'Vai trò ',
            field: 'roleName',
            thClass: 'text-center',
          },
          {
            label: 'Thao tác',
            field: 'action',
            thClass: 'text-center',
            tdClass: 'text-center',
            sortable: false,
          },
        ],
        rows: [],
      },
      filter: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 20,
        code: '',
      },
      statusOptions: [
        { value: 1, label: 'Đang hoạt động' },
        { value: 0, label: 'Không hoạt động' },
      ],
      currentResearch: undefined,
    }
  },
  computed: {
    ...mapGetters({
      dataList: 'research/dataMemberArr',
      total: 'research/total',
    }),
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.RESEARCH_MEMBER)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.RESEARCH_MEMBER)
    },
  },
  async created() {
    // init data FROM API
    await this.getInitData()
  },
  methods: {
    // handle even
    ...mapActions({
      getData: 'research/getDataMember',
      deleteData: 'research/deleteDataMember',
    }),

    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.getInitData()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.getInitData()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      this.getInitData()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.getInitData()
    },

    async getInitData() {
      this.isLoading = true
      try {
        const params = {
          currentPage: this.filter.page,
          itemsPerPage: this.filter.perPage,
          researchId: this.dataForm.id ? this.dataForm.id : null,
        }
        await this.getData(params)
        this.table.rows = this.dataList
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'errors', '')
      } finally {
        this.isLoading = false
      }
    },

    editItem(data) {
      this.dataForm = data
      this.$bvModal.show('form')
    },

    deleteItem(data) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa thành viên <span class="text-danger">${data.employeeName}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            const response = await this.deleteData(data.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.showToast('Đã xóa thành công!', 'CheckIcon', 'success')
                this.$emit('saveDataSuccess')
              } else {
                this.showToast(`Có lỗi xảy ra ${message}`, 'XCircleIcon', 'warning')
              }
            }
          } catch (e) {
            this.showToast(`[${e.code}] ${e.message}`, 'errors', '')
          } finally {
            await this.getInitData()
          }
        }
      })
    },

    showModal() {
      this.currentResearch = {
        researchId: this.dataForm.id,
      }
      this.$bvModal.show('formAddEmployee')
    },

    async successFormAdd() {
      this.$bvModal.hide('formAddEmployee')
      await this.getInitData()
      this.$emit('saveDataSuccess')
    },

    getStatusName(id) {
      return this.statusOptions.find(status => status.id === id).label
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },

  },
}
</script>

<style scoped>

</style>
