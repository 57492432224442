<template>
  <validation-observer
    ref="inventionSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="inventionSaveModal"
      body-class="position-static"
      centered
      size="lg"
      :title="isCreated ? 'Thêm sáng chế, giải pháp' : 'Cập nhật sáng chế, giải pháp'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên sáng chế, giải pháp <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên sáng chế, giải pháp"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetInvention.name"
                  name="name"
                  placeholder="Nhập tên sáng chế, giải pháp"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group label-for="typeId">
              <template v-slot:label>
                Loại sáng chế, giải pháp <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Loại sáng chế, giải pháp"
                rules="required"
              >
                <v-select
                  id="typeId"
                  v-model="targetInvention.factResearchInventionTypeId"
                  label="name"
                  :options="types"
                  :reduce="option => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Thuộc nghiên cứu khoa học"
              label-for="researchId"
            >
              <v-select
                id="researchId"
                v-model="targetInvention.researchId"
                label="name"
                :options="researches"
                :reduce="option => option.id"
                :disabled="!!researchId"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Tổ chức cấp bằng"
              label-for="patentOrganization"
            >
              <b-form-input
                id="patentOrganization"
                v-model="targetInvention.patentOrganization"
                name="patentOrganization"
                placeholder="Nhập tổ chức cấp bằng"
              />
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Số bằng"
              label-for="patentNo"
            >
              <b-form-input
                id="patentNo"
                v-model="targetInvention.patentNo"
                name="patentNo"
                placeholder="Nhập số bằng"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Ngày cấp"
              label-for="patentDate"
            >
              <b-input-group class="input-group-merge">
                <b-form-datepicker
                  id="startDate"
                  v-model="targetInvention.patentDate"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="vi"
                  format="dd/mm/yyyy"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="clearPatentDate"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Số đơn"
              label-for="applNo"
            >
              <b-form-input
                id="applNo"
                v-model="targetInvention.applNo"
                name="applNo"
                placeholder="Nhập số đơn"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Công bố bằng"
              label-for="patentPublication"
            >
              <b-form-input
                id="patentPublication"
                v-model="targetInvention.patentPublication"
                name="patentPublication"
                placeholder="Nhập công bố bằng"
              />
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="ipc"
              label-for="ipc"
            >
              <b-form-input
                id="ipc"
                v-model="targetInvention.ipc"
                name="ipc"
                placeholder="Nhập số bằng"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Chủ bằng"
              label-for="proprietor"
            >
              <b-form-input
                id="proprietor"
                v-model="targetInvention.proprietor"
                name="proprietor"
                placeholder="Nhập chủ bằng"
              />
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  id="status"
                  v-model="targetInvention.status"
                  label="text"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Tóm tắt"
              label-for="summary"
            >
              <ckeditor
                ref="ckRef"
                v-model="targetInvention.summary"
                :config="editorConfig"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('inventionSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BButton, BRow, BCol, BInputGroup, BInputGroupAppend, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { RESEARCH_INVENTION_STATUSES } from '@/const/status'
import { getUser } from '@/auth/utils'

export default {
  name: 'InventionSave',
  components: {
    BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    invention: {
      type: Object,
      default: undefined,
    },
    researchId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetInvention: {
        organizationId: null,
        name: '',
        factResearchInventionTypeId: null,
        researchId: null,
        patentOrganization: '',
        patentNo: '',
        patentDate: null,
        applNo: '',
        patentPublication: '',
        ipc: '',
        proprietor: '',
        status: 1,
        summary: '',
      },
      editorConfig: {
        versionCheck: false,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      types: 'factResearchInventionType/factResearchInventionTypes',
      researches: 'invention/researches',
    }),
    statusOptions() {
      return RESEARCH_INVENTION_STATUSES
    },
    isCreated() {
      return !this.invention
    },
  },
  methods: {
    ...mapActions({
      createInvention: 'invention/createInvention',
      updateInvention: 'invention/updateInvention',
      getAllTypes: 'factResearchInventionType/getAll',
      getAllResearchesByOrganization: 'invention/getAllResearchesByOrganization',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.invention) {
        this.targetInvention = { ...this.invention }
      } else {
        this.targetInvention = { ...this.targetInvention, organizationId: this.user.orgId }
        if (this.researchId) {
          this.targetInvention = { ...this.targetInvention, researchId: this.researchId }
        }
      }
      this.isLoading = true
      try {
        await Promise.all([
          this.getAllTypes(),
          this.getAllResearchesByOrganization({ organizationId: this.user.orgId }),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .inventionSaveFormRef
        .reset()
      this.targetInvention = {
        organizationId: null,
        name: '',
        factResearchInventionTypeId: null,
        researchId: null,
        patentOrganization: '',
        patentNo: '',
        patentDate: null,
        applNo: '',
        patentPublication: '',
        ipc: '',
        proprietor: '',
        status: 1,
        summary: '',
      }
      if (this.$refs.ckRef.instance) this.$refs.ckRef.instance.destroy()
    },
    async onSave(type = null) {
      const valid = this.$refs
        .inventionSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createInvention(this.targetInvention)
            : await this.updateInvention(this.targetInvention)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('inventionSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    clearPatentDate() {
      this.targetInvention.patentDate = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
