<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group
              label-for="categoryId"
              label="Loại đề tài"
            >
              <v-select
                v-model="filter.categoryId"
                :options="categories"
                :reduce="option => option.id"
                label="name"
                placeholder="Chọn loại đề tài"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group
              label-for="fieldId"
              label="Lĩnh vực"
            >
              <v-select
                v-model="filter.fieldId"
                label="name"
                :options="fields"
                :reduce="option => option.id"
                placeholder="Chọn lĩnh vực"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group
              label-for="levelId"
              label="Cấp độ"
            >
              <v-select
                v-model="filter.levelId"
                :options="levels"
                :reduce="option => option.id"
                label="name"
                placeholder="Chọn cấp độ"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="text-center">
              <b-button
                v-if="creatable"
                variant="primary"
                @click="getDataSourcesFromStore"
              >
                <span class="text-nowrap">
                  <feather-icon icon="SearchIcon" /> Tìm kiếm
                </span>
              </b-button>
              <b-button
                v-if="creatable"
                variant="success"
                class="ml-2"
                @click="showModal"
              >
                <span class="text-nowrap">
                  <feather-icon icon="PlusIcon" /> Thêm mới
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-1 mt-1">
              <span class="text-nowrap">
                Hiển thị {{
                  filter.itemsPerPage * (filter.currentPage - 1) + 1
                }} đến {{ (filter.itemsPerPage * (filter.currentPage) >= total) ? total : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{
                  total
                }} bản ghi
              </span>
            </div>
            <vue-good-table
              mode="remote"
              style-class="vgt-table striped bordered research-table"
              row-style-class="vgt-row"
              :columns="columns"
              :rows="dataSources"
              :pagination-options="{enabled: true}"
              :total-rows="total"
              :line-numbers="true"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <!-- pagination -->
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'startDate'">
                  {{ formatDate(props.row.startDate) }}
                </span>
                <span v-else-if="props.column.field === 'endDate'">
                  {{ formatDate(props.row.endDate) }}
                </span>
                <span v-else-if="props.column.field === 'status'">
                  {{ getStatusName(props.row.status) }}
                </span>
                <span
                  v-else-if="props.column.field === 'action'"
                  class="thead-group-action"
                >
                  <b-button-group>
                    <b-button
                      v-if="updatable"
                      v-b-tooltip.hover
                      title="Chỉnh sửa"
                      placement="bottom"
                      variant="primary"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="editItem(props.row)"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-dropdown
                      right
                      variant="success"
                      no-caret
                      size="sm"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreHorizontalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item
                        v-if="researchMemberReadable"
                        variant="success"
                        class="btn-icon"
                        size="sm"
                        @click="configEmployee(props.row)"
                      >
                        <feather-icon icon="UsersIcon" /> Quản lý thành viên
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="researchesReviewBoardsReadable"
                        variant="warning"
                        class="btn-icon"
                        size="sm"
                        @click="configReviewBoard(props.row)"
                      >
                        <feather-icon icon="FlagIcon" /> Quản lý hội đồng
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="researchInventionFetchable"
                        v-b-modal.researchInventionModal
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        @click="currentResearchId = props.row.id"
                      >
                        <feather-icon icon="SunIcon" /> Sáng chế giải pháp
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="researchTechTransferFetchable"
                        v-b-modal.researchTechTransferModal
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        @click="currentResearchId = props.row.id"
                      >
                        <feather-icon icon="MoveIcon" /> Chuyền giao công nghệ
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="researchArticleFetchable"
                        v-b-modal.researchArticleModal
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        @click="currentResearchId = props.row.id"
                      >
                        <feather-icon icon="BookIcon" /> Bài báo khóa học
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="props.row.status === 0"
                        variant="info"
                        class="btn-icon"
                        size="sm"
                        @click="sendForReview(props.row)"
                      >
                        <feather-icon icon="ArrowUpIcon" /> Gửi xét duyệt
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="deletable"
                        variant="danger"
                        class="btn-icon"
                        size="sm"
                        @click="deleteItem(props.row)"
                      >
                        <feather-icon icon="TrashIcon" /> Xóa
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-button-group>
                </span>
                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
              </template>
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Hiển thị {{
                        filter.itemsPerPage * (filter.currentPage - 1) + 1
                      }} đến {{ (filter.itemsPerPage * (filter.currentPage) >= total) ? total : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{
                        total
                      }} bản ghi
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị </span>
                    <b-form-select
                      v-model="filter.itemsPerPage"
                      :options="['10', '20', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap"> bản ghi/trang</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="filter.itemsPerPage"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    />
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      id="form"
      ref="form"
      ok-only
      ok-title="Chấp nhận"
      centered
      size="lg"
      :title="!dataForm ? 'Thêm đề tài' : 'Cập nhật đề tài'"
      no-close-on-backdrop
      :hide-footer="true"
    >
      <Form
        :data-form="dataForm"
        @saveDataSuccess="saveDataSuccess"
      />
    </b-modal>

    <b-modal
      id="formEmployee"
      ref="formEmployee"
      ok-only
      ok-title="Chấp nhận"
      centered
      size="lg"
      title="Quản lý thành viên"
      :hide-footer="true"
    >
      <FormEmployeeResearch
        :data-form="dataForm"
        @saveDataSuccess="saveDataSuccess"
      />
    </b-modal>

    <b-modal
      id="formReviewBoard"
      ref="formReviewBoard"
      ok-only
      ok-title="Chấp nhận"
      centered
      size="lg"
      title="Quản lý hội đồng"
      :hide-footer="true"
    >
      <FormResearchesReviewBoard :data-form="dataForm" />
    </b-modal>

    <b-modal
      id="researchInventionModal"
      centered
      size="xl"
      body-class="position-static"
      title="Danh sách sáng chế giải pháp"
      hide-footer
    >
      <ResearchInvention :research-id="currentResearchId" />
    </b-modal>

    <b-modal
      id="researchTechTransferModal"
      centered
      size="xl"
      body-class="position-static"
      title="Danh sách chuyển giao công nghệ"
      hide-footer
    >
      <ResearchTechTransfer :research-id="currentResearchId" />
    </b-modal>

    <b-modal
      id="researchArticleModal"
      centered
      size="xl"
      body-class="position-static"
      title="Danh sách bài báo khoa học"
      hide-footer
    >
      <ResearchArticle :research-id="currentResearchId" />
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BButton, BCard, BCol, BFormSelect, BOverlay, BPagination, BRow, VBTooltip,
  BFormGroup, BDropdown, BDropdownItem, BButtonGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import '@core/scss/vue/libs/vue-good-table.scss'
import Form from '@/views/research/Form.vue'
import FormEmployeeResearch from '@/views/research/FormEmployeeResearch.vue'
import FormResearchesReviewBoard from '@/views/research/FormResearchesReviewBoard.vue'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { RESEARCH_STATUSES } from '@/const/status'
import moment from 'moment'
import ResearchArticle from '@/views/research/article/ResearchArticle.vue'
import ResearchInvention from '@/views/research/invention/Invention.vue'
import ResearchTechTransfer from '@/views/research/tech-transfer/TechTransfer.vue'

export default {
  name: 'Index',
  components: {
    VueGoodTable,
    vSelect,
    BButton,
    BCard,
    BCol,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    Form,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    FormEmployeeResearch,
    FormResearchesReviewBoard,
    ResearchArticle,
    ResearchInvention,
    ResearchTechTransfer,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      columns: [
        {
          label: 'Mã đề tài',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã đề tài',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tên đề tài',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên đề tài',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Họ và tên',
          field: 'employees',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập họ và tên',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ngày bắt đầu',
          field: 'startDate',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày kết thúc',
          field: 'endDate',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: RESEARCH_STATUSES,
            placeholder: 'Chọn trạng thái',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: null,
        categoryId: null,
        fieldId: null,
        levelId: null,
        name: null,
        code: null,
        employees: null,
        employeeId: null,
        status: null,
        sort: null,
      },
      dataForm: undefined,
      currentResearchId: null,
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'research/dataArr',
      categories: 'factScientificTopics/dataArr',
      dataRoles: 'factResearchRoles/dataArr',
      fields: 'factResearchFields/dataArr',
      levels: 'factTopicLevels/dataArr',
      total: 'research/total',
      years: 'factYear/years'
    }),
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.RESEARCH)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.RESEARCH)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.RESEARCH)
    },
    researchMemberReadable() {
      return hasPermissionForResource(PermissionCode.READ, ResourceCode.RESEARCH_MEMBER)
    },
    researchesReviewBoardsReadable() {
      return hasPermissionForResource(PermissionCode.READ, ResourceCode.RESEARCHES_REVIEW_BOARDS)
    },
    researchArticleFetchable() {
      return hasPermissionForResource(PermissionCode.FETCH, ResourceCode.RESEARCH_ARTICLE)
    },
    researchInventionFetchable() {
      return hasPermissionForResource(PermissionCode.FETCH, ResourceCode.RESEARCH_INVENTION)
    },
    researchTechTransferFetchable() {
      return hasPermissionForResource(PermissionCode.FETCH, ResourceCode.RESEARCH_TECH_TRANSFER)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.getCategories({ currentPage: 1, itemsPerPage: 100 }),
        this.getFields({ currentPage: 1, itemsPerPage: 100 }),
        this.getLevels({ currentPage: 1, itemsPerPage: 100 }),
      ])
      this.updateParams({ organizationId: this.user.orgId })
      if (!this.creatable) {
        this.updateParams({ employeeId: this.user.employeeId })
      }
    } catch (e) {
      this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getDataSources: 'research/getData',
      getCategories: 'factScientificTopics/getData',
      getFields: 'factResearchFields/getData',
      getLevels: 'factTopicLevels/getData',
      deleteData: 'research/deleteData',
      saveData: 'research/saveData',
      getYears: 'factYear/getYears'
    }),

    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(params) {
      this.updateParams({ currentPage: params.currentPage })
      await this.getDataSourcesFromStore()
    },
    async onPerPageChange(params) {
      this.updateParams({ itemsPerPage: params.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onSortChange(params) {
      if (params.length > 0) {
        const { field, type } = params[0]
        if (field === 'code') {
          this.updateParams({ sort: `1_${type}` })
        }
        if (field === 'name') {
          this.updateParams({ sort: `2_${type}` })
        }
        if (field === 'startDate') {
          this.updateParams({ sort: `3_${type}` })
        }
        if (field === 'endDate') {
          this.updateParams({ sort: `4_${type}` })
        }
      }
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      const fields = this.columns.map(column => column.field)
      fields.forEach(field => {
        if (Object.hasOwn(columnFilters, field)) {
          const data = {}
          data[field] = columnFilters[field]
          this.updateParams(data)
        }
      })
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getDataSources(this.filter)
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },

    editItem(data) {
      this.dataForm = data
      this.$bvModal.show('form')
    },

    deleteItem(data) {
      this.$swal({
        title: 'Bạn chắc chắn muốn đề tài khoa học?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          this.isLoading = true
          try {
            const response = await this.deleteData(data.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.showToast('Đã xóa thành công!', 'CheckIcon', 'success')
                await this.getDataSources(this.filter)
              } else {
                this.showToast(`Có lỗi xảy ra ${message}`, 'XCircleIcon', 'warning')
              }
            }
          } catch (e) {
            this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
          } finally {
            this.isLoading = false
          }
        }
      })
    },

    showModal() {
      this.dataForm = undefined
      this.$bvModal.show('form')
    },

    configEmployee(dataRow) {
      this.dataForm = dataRow
      this.$bvModal.show('formEmployee')
    },

    configReviewBoard(dataRow) {
      this.dataForm = dataRow
      this.$bvModal.show('formReviewBoard')
    },

    async sendForReview(dataRow) {
      const body = { ...dataRow, ...{ status: 1 } }
      this.loading = true
      try {
        const response = await this.saveData(body)
        const { isSuccessful, message } = response
        if (isSuccessful) {
          this.showToast('Gửi xét duyệt thành công!', 'CheckIcon', 'success')
          await this.getDataSources(this.filter)
        } else {
          this.showToast(message, 'XCircleIcon', 'danger')
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },

    async saveDataSuccess() {
      await this.getDataSourcesFromStore()
    },

    getStatusName(id) {
      if (id === null || id === undefined) return ''
      const found = RESEARCH_STATUSES.find(s => s.value === id)
      return found ? found.text : ''
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
  },
}
</script>

<style lang="scss">
.research-table {
  min-height: 220px;
}
.dropdown-menu.show {
  height: 15rem !important;
  overflow-y: auto !important;
}
</style>
