<template>
  <b-modal
    id="techTransferMemberModal"
    body-class="position-static"
    centered
    hide-footer
    no-close-on-backdrop
    size="xl"
    title="Thành viên tham gia chuyển giao công nghệ"
    @show="onShow"
  >
    <b-container fluid>
      <b-card no-body>
        <div class="m-2">
          <b-row v-if="creatable">
            <b-col cols="12">
              <div class="text-right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.memberSaveModal
                  variant="success"
                  @click="onCreateMember"
                >
                  <span class="text-nowrap text-right">
                    <feather-icon icon="PlusIcon" /> Thêm thành viên
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row :class="{'mt-1': creatable}">
            <b-col cols="12">
              <vue-good-table
                :columns="columns"
                :rows="dataSources"
                :pagination-options="paginationOptions"
                :line-numbers="true"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'factResearchRoleId'">
                    {{ getRoleName(props.row.factResearchRoleId) }}
                  </span>
                  <span v-else-if="props.column.field === 'action'">
                    <b-button
                      v-if="updatable"
                      v-b-modal.memberSaveModal
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      @click="onEditMember(props.row)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-if="deletable"
                      variant="danger"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="onDeleteMember(props.row)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{ itemsPerPage * (currentPage - 1) + 1 }}
                        đến {{ itemsPerPage * currentPage }} &nbsp; của {{ props.total }} bản ghi
                      </span>
                    </div>

                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap">Hiển thị</span>
                      <b-form-select
                        v-model="itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap">bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => onPageChange(value, props)"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-container>
    <b-overlay
      no-wrap
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      :show="isLoading"
    />

    <TechTransferMemberSave
      :research-tech-transfer-id="researchTechTransferId"
      :member="currentMember"
      @succeed="onShow"
    />
  </b-modal>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormSelect, BModal, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { PermissionCode, ResourceCode } from '@/const/code'
import { hasPermissionForResource } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import TechTransferMemberSave from '@/views/research/tech-transfer/member/TechTransferMemberSave.vue'
import { ResearchRoleType } from '@/const/type'

export default {
  name: 'TechTransferMember',
  directives: {
    Ripple,
  },
  components: {
    TechTransferMemberSave,
    BCard,
    BRow,
    BCol,
    BContainer,
    BButton,
    BModal,
    BFormSelect,
    BOverlay,
    BPagination,
    VueGoodTable,
  },
  props: {
    researchTechTransferId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      currentMember: undefined,
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'techTransferMember/techTransferMembers',
      roles: 'factResearchRoles/dataArr',
    }),
    columns() {
      return [
        {
          label: 'Họ tên',
          field: 'fullName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập họ tên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Phòng ban',
          field: 'departmentName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập phòng ban',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Vai trò',
          field: 'factResearchRoleId',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.roles.map(role => ({ value: role.id, text: role.name })),
            placeholder: 'Chọn vai trò',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.RESEARCH_TECH_TRANSFER_MEMBER)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.RESEARCH_TECH_TRANSFER_MEMBER)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.RESEARCH_TECH_TRANSFER_MEMBER)
    },
  },
  methods: {
    ...mapActions({
      getTechTransferMembers: 'techTransferMember/getTechTransferMembers',
      deleteTechTransferMember: 'techTransferMember/deleteTechTransferMember',
      getRolesByType: 'factResearchRoles/getRolesByType',
    }),
    async onShow() {
      this.isLoading = true
      try {
        await this.getRolesByType({ type: ResearchRoleType.MEMBER })
        await this.getTechTransferMembers({ researchTechTransferId: this.researchTechTransferId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getRoleName(id) {
      return this.roles.find(role => role.id === id).name
    },
    onCreateMember() {
      this.currentMember = undefined
    },
    onEditMember(member) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = member
      this.currentMember = rest
    },
    onDeleteMember(member) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa thành viên <span class="text-danger">${member.fullName}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteTechTransferMember(member.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getTechTransferMembers({ researchTechTransferId: this.researchTechTransferId })
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    onPageChange(value, props) {
      this.currentPage = value
      props.pageChanged({ currentPage: value })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
